/* unplugin-vue-components disabled */import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'TextEditorBlock',
  props: {
    // 照片檢視
    imageViewer: {
      type: Boolean,
      default: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var refRoot = ref(null); // 檢查外部連結

    function checkExternalLink() {
      $(refRoot.value).find('[href]').each(function (idx, link) {});
    } // 照片檢視


    function enableImageViewer() {
      if (enableImageViewer.gallery) {
        enableImageViewer.gallery.destroy();
      }

      var gallery = new Viewer(refRoot.value, {
        zIndex: 5000,
        navbar: false,
        toolbar: false,
        transition: false
      });
      enableImageViewer.gallery = gallery;
    }

    onMounted(function () {
      MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
      var observer = new MutationObserver(function (mutations, observer) {
        checkExternalLink();
        enableImageViewer();
      }); // define what element should be observed by the observer
      // and what types of mutations trigger the callback
      // 不需要另外處理memory leak，本元件銷毀後observer會自動被GC
      // @see https://stackoverflow.com/a/50821419/20237601

      observer.observe(refRoot.value, {
        childList: true
      });
      checkExternalLink();

      if (props.imageViewer) {// enableImageViewer();
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "text-editor-block"
      }, [_renderSlot(_ctx.$slots, "default")], 512);
    };
  }
};