/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MBed.vue';
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "msg-board__top"
};
var _hoisted_2 = {
  class: "msg-board__item"
};
var _hoisted_3 = {
  class: "msg-board__main"
};
var _hoisted_4 = {
  class: "msg-board__txtarea"
};
var _hoisted_5 = {
  class: "msg-board__body"
};
var _hoisted_6 = {
  class: "msg-board__main"
};
var _hoisted_7 = {
  class: "msg-board__item-info"
};
var _hoisted_8 = {
  class: "msg-board__item-title"
};
var _hoisted_9 = {
  class: "msg-board__item-subinfo"
};
import { Post } from "@/js/services/baseService";
export default {
  __name: 'MsgBoard',
  props: {
    post_id: {
      type: [Number, String],
      default: 0
    },
    modelValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refTextarea = ref(null);
    var textareaValue = ref('');

    function checkHeight() {// var textarea = refTextarea.value;
      // if ( textarea.scrollHeight > textarea.clientHeight ){
      //     textarea.style.height = textarea.scrollHeight
      // } else {
      // }
    }

    function reply() {
      if (!textareaValue.value || !textareaValue.value.trim()) {
        appSwalCustomDialog.alert({
          title: '提示',
          content: '請輸入回覆內容!'
        });
        return;
      }

      Post.comment({
        post_id: props.post_id,
        content: textareaValue.value
      }).then(function (res) {
        props.modelValue.unshift(res.data);
        emit('update:modelValue', props.modelValue);
      });
      textareaValue.value = '';
    }

    return function (_ctx, _cache) {
      var _component_MBed = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_MBed, {
        class: "msg-board"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_withDirectives(_createElementVNode("textarea", {
            ref_key: "refTextarea",
            ref: refTextarea,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _isRef(textareaValue) ? textareaValue.value = $event : null;
            }),
            placeholder: "請輸入回覆內容",
            name: "",
            onInput: checkHeight
          }, null, 544), [[_vModelText, _unref(textareaValue)]]), _createElementVNode("a", {
            class: "msg-board__btn",
            href: "javascript:;",
            onClick: reply
          }, " 送出 ")])])])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.modelValue, function (item) {
            return _openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "msg-board__item"
            }, [_createElementVNode("div", {
              class: "msg-board__avatar",
              style: _normalizeStyle({
                'background-color': item.color
              })
            }, _toDisplayString(item.name.substr(0, 1)), 5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.author), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(item.content), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.date), 1)])]);
          }), 128))])];
        }),
        _: 1
      });
    };
  }
};